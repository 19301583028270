<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <div class="card card-custom">
      <!-- user info -->
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{ $t("user_details.user_info") }}</h3>
        </div>
        <div class="card-toolbar">
          <router-link
            :to="`/users/${$route.params.id}`"
            class="btn btn-sm btn-secondary text-primary font-weight-bold"
          >
            <i class="flaticon2-back text-primary fa-1x"></i> {{ $t("commons.back") }}
          </router-link>
        </div>
      </div>
      <div class="card-body">
        <form class="form">
          <div data-wizard-type="step-content" data-wizard-state="current">
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <label class="col-form-label">{{ $t("commons.name") }}</label>
                  <validation-provider
                    rules="required"
                    :name="$t('commons.name')"
                    v-slot="{ classes, errors }"
                    v-if="!isLoading"
                  >
                    <input
                      class="form-control"
                      type="text"
                      v-model="formData.name"
                      :class="classes"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else>
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">{{
                    $t("commons.surname")
                  }}</label>
                  <validation-provider
                    rules="required"
                    :name="$t('commons.surname')"
                    v-slot="{ classes, errors }"
                    v-if="!isLoading"
                  >
                    <input
                      class="form-control"
                      type="text"
                      v-model="formData.surname"
                      :class="classes"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else>
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">{{
                    $t("user_details.user_group")
                  }}</label>
                  <validation-provider
                    rules="required"
                    :name="$t('user_details.user_group')"
                    v-slot="{ classes, errors }"
                    v-if="!isLoading"
                  >
                    <multiselect
                      v-model="selectedGroup"
                      :options="groups"
                      :internal-search="true"
                      :allow-empty="false"
                      open-direction="bottom"
                      deselect-label=""
                      select-label=""
                      selected-label=""
                      track-by="name"
                      label="name"
                      :placeholder="$t('user_details.user_group')"
                      :class="classes"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                      <template slot="option" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                      <template slot="noOptions">{{
                        "List is empty"
                      }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else>
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- communication info -->
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{ $t("user_details.user_contact_info") }}</h3>
        </div>
      </div>
      <div class="card-body">
        <form class="form">
          <div data-wizard-type="step-content" data-wizard-state="current">
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <label class="col-form-label">{{ $t("commons.mobile_phone") }}</label>
                  <validation-provider
                    rules="required"
                    v-slot="{ classes, errors }"
                    v-if="!isLoading"
                  >
                    <div class="input-group" :class="classes">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="la la-phone"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        :name="$t('commons.mobile_phone')"
                        minlength="10"
                        :placeholder="
                          $t('commons.phone_example', {
                            value: ' - 533xxxxxxx',
                          })
                        "
                        aria-describedby="basic-addon1"
                        class="form-control"
                        :class="classes"
                        v-model="formData.phone"
                      />
                      <span class="error__message">{{ errors[0] }}</span>
                    </div>
                  </validation-provider>
                  <div v-else>
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-form-label">{{$t("commons.e_mail")}}</label>
                  <validation-provider
                    rules="required"
                    v-slot="{ classes, errors }"
                    v-if="!isLoading"
                  >
                    <div class="input-group" :class="classes">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><i class="la la-at"></i
                        ></span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        :class="classes"
                        :name="$t('commons.e_mail')"
                        :placeholder="$t('commons.e_mail')"
                        v-model="formData.email"
                      />
                      <span class="error__message">{{ errors[0] }}</span>
                    </div>
                  </validation-provider>
                  <div v-else>
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-form-label">{{
                    $t("user_details.address")
                  }}</label>
                  <div v-if="!isLoading">
                    <input
                      class="form-control"
                      type="text"
                      v-model="formData.address"
                    />
                  </div>
                  <div v-else>
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- active passive status -->
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{ $t("user_details.account_state") }}</h3>
        </div>
      </div>
      <div class="card-body">
        <form class="form">
          <div data-wizard-type="step-content" data-wizard-state="current">
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <div v-if="!isLoading">
                    <div class="radio-inline col-form-label pt-0">
                      <label class="radio" for="active">
                        <input
                          type="radio"
                          id="active"
                          :checked="userIsActive"
                          @input="changeUserStatus(10)"
                        />
                        <span></span>{{ $t('user_details.status_types.10') }}
                      </label>
                      <label class="radio" for="passive">
                        <input
                          type="radio"
                          id="passive"
                          :checked="userIsPassive"
                          @input="changeUserStatus(30)"
                        />
                        <span></span>{{ $t('user_details.status_types.30') }}
                      </label>
                    </div>
                  </div>
                  <div v-else>
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-primary"
            @click="handleSubmit(_handleSubmit)"
          >
            {{ $t("commons.submit") }}
          </button>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { Skeleton } from 'vue-loading-skeleton';


export default {
  components: {
    Multiselect,
    Skeleton
  },
  data() {
    return {
      formData: {
        name: "",
        surname: "",
        group_id: "",
        phone: "",
        email: "",
        address: "",
        status: "",
      },
      selectedGroup: null,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.userdetails.isLoading,
      user: (state) => state.userdetails.editUser.user,
      groups: (state) => state.userdetails.editUser.groups
    }),
    userIsActive() {
      if(this.formData.status) {
        return parseInt(this.formData.status) === 10;
      }
      return false;
    },
    userIsPassive() {
      if(this.formData.status) {
        return parseInt(this.formData.status) === 30;
      }
      return false;
    },
  },
  methods: {
    _handleSubmit() {
        this.formData.group_id = this.selectedGroup.id;
        // console.log(this.formData);
        this.$store.dispatch('userdetails/updateUserInfoAction', {
          id: this.$route.params.id,
          formData: this.formData
        })
    },
    changeUserStatus(_status) {
      this.formData.status = _status;
    },
  },
  created() {
    this.$store.dispatch('userdetails/editUserInfoAction', this.$route.params.id).then(() => {
      const {
        name, surname, group_id,
        phone, email, address, status
      } = this.user;
      this.formData.name = name ?? '';
      this.formData.surname = surname ?? '';
      this.formData.group_id = group_id ?? '';
      this.selectedGroup = this.groups.find(item => +item.id === +group_id);
      
      this.formData.phone = phone ?? '';
      this.formData.email = email ?? '';
      this.formData.address = address ?? '';
      this.formData.status = status ?? '';
    })
  }
};
</script>
